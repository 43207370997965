import { Grid, useStyles } from '@archipro-design/aria';

import * as S from './PageLayout.style';
import type { PropsWithChildren } from 'react';

interface PageSectionsProps {}

const PageSections: React.FC<PropsWithChildren<PageSectionsProps>> = ({
    children,
}) => {
    const { css } = useStyles();

    return (
        <Grid columns={'100%'} className={css(S.Sections)}>
            {children}
        </Grid>
    );
};

export default PageSections;
